@page {
    size: A4;
    margin: 20mm;
}

.body {
    font-family: Arial, sans-serif !important;
    color: #000000;
    padding: 10%;
    background:#ffffff;
   
}

.box {
    display: flex;
    justify-content: center;
    color: #000000;
    font-size: 30px;
    font-weight: 800;
}

.ak {
    display: flex !important;
    justify-content: space-between !important;
    height: fit-content !important;
}

.header {

    margin-bottom: 50px !important;
}


.header table {
    border: 1px solid black !important;
    width: 100% !important;
    border-collapse: collapse !important;
}

.header th,
td {
    border: 1px solid black !important;
    padding: 8px !important;
    text-align: center !important;
}

.header th {
    background-color: #f2f2f2 !important;
}

.totals td {
    border: none !important;
    text-align: right !important;
    padding-right: 20px !important;
}

